import React from 'react'
import "./hos.css"
export default function Ticker() {
  return (
    <>


<div className='first-animation-flow'>
  
  <div className="slide-right">
    <p><strong>Chambers are available for Doctors</strong></p>
  </div>
  
  <div className="line fade-in"></div>
  
  <div className="slide-left">
    <p><strong><i>(Surgeon / Gynacologist / ENT / Paediatrician)</i></strong></p>
  </div>
  
</div>
    </>
  )
}

