import React, { useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import patientDataService from "../Services/patientServices";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

function Adminappointment() {
  const [name, setName] = useState();
  const [email, setEmail] = useState("NA");
  const [phone, setPhone] = useState();
  const [date, setDate] = useState();
  const [slot, setSlot] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [status, setStatus] = useState("Requested");
  const [address, setAddress] = useState("Not provided");
  const [services, setServices] = useState();
  const [selectDoctor, setSelectDoctor] = useState();
  const [isSlotValid, setIsSlotValid] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();

  const navigate = useNavigate();
  const form = useRef();

  const validateSlot = (doctor, selectedSlot) => {
    if (
      (doctor === "Dr S.M. Holkar" && selectedSlot === "morning") ||
      (doctor === "Dr Sandhya Holkar" && selectedSlot === "evening")
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if the selected date is in the past
    const today = new Date().setHours(0, 0, 0, 0); // Current date without time
    const selectedDate = new Date(date).setHours(0, 0, 0, 0); // Selected date without time

    // const isWeekend = selectedDate.getDay() === 6 || selectedDate.getDay() === 0;
    // const isPastDate = selectedDate < new Date();
    // if (isWeekend || isPastDate) {
    //   toast.error('Please Select Date Between Monday to Friday', {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // return;

    const newPatient = {
      name,
      age,
      address,
      slot,
      date,
      phone,
      services,
      gender,
      status,
      email,
      // selectedDoctor
      selectDoctor: selectedDoctor,
    };

    console.log("selectedDoctor for appointment is ", selectedDoctor);

    if (!validateSlot(selectedDoctor, slot)) {
      toast.error("Selected doctor is not available for the chosen slot.", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    try {
      await patientDataService.addPatients(newPatient);
      toast.success("Successfully booked appointment", {
        position: toast.POSITION.TOP_CENTER,
      });

      // Check if the selected date is in the future
      if (selectedDate >= today) {
        // Only send email if the date is not in the past
        const appEnv = process.env.REACT_APP_ENV_ID;
        const emailServiceConfig = {
          /* dev: {
      service_id: "service_5d90s1h",
      template_id: "template_fi30mrs",
    },
    prod: {
      service_id: "service_5d90s1h",
      template_id: "template_9xkrn3c", 
  
    },*/
          dev: {
            service_id: "service_y6uhwzc",
            template_id: "template_hopgdsa",
          },
          prod: {
            service_id: "service_y6uhwzc",
            template_id: "template_hopgdsa",
          },
        };

        const config =
          appEnv === "production"
            ? emailServiceConfig.prod
            : emailServiceConfig.dev;

        emailjs.init(config.service_id);

        emailjs
          .sendForm(
            config.service_id,
            config.template_id,
            form.current,
            "Dty93EGIGofgNtab4" //"ITToyXgW22AcCNZTY"
          )
          .then(
            (result) => {
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      }
      navigate("/PatientDashboard");
    } catch (error) {
      console.log("error is ", error);
      toast.error("Please fill in all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <div className="appointment">
      <div className="box">
        <form className="form" onSubmit={handleSubmit} ref={form}>
          {/* <label>Name</label> */}

          <input
            type="name"
            name="user_name"
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
            required
          />
          {/* <label>Email</label> */}

          <input
            type="email"
            name="user_email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            //  required
          />
          {/* <label>Mobile Number</label> */}

          <input
            type="phone"
            name="user_phone"
            placeholder="Mobile no."
            pattern="[0-9]{10}"
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          {/* <label>Date</label> */}

          <input
            type="date"
            placeholder="Date"
            onChange={(e) => setDate(e.target.value)}
            // min={new Date().toISOString().split('T')[0]}
            required
          />

          <div className="select">
            <label> Doctor </label>
            <select
              type="Doctor"
              onChange={(e) => {
                setSelectedDoctor(e.target.value);
                //  setSelectDoctor(e.target.value);
                setSlot("");
              }}
              required
            >
              <option value="">Select Doctor</option>
              <option value="Dr S.M. Holkar">Dr S.M. Holkar</option>
              <option value="Dr Sandhya Holkar">Dr Sandhya Holkar</option>
              <option value="Other">Other</option>
            </select>
            <label>Gender </label>
            <select
              type="gender"
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select Gender</option>
              <option value="female">Female </option>
              <option value="male">Male</option>
              <option value="other">Other</option>
            </select>
            <div className="status">
              <label>Status </label>
              <div className="status">
                <select onChange={(e) => setStatus(e.target.value)} required>
                  <option value="visited">Visited</option>
                  <option value="not-visited">Not-visited</option>
                </select>
              </div>
            </div>
            <label>Slot</label>
            <select
              type="slot"
              name="user_slot"
              onChange={(e) => {
                setSlot(e.target.value);
                setIsSlotValid(validateSlot(selectedDoctor, e.target.value)); // Perform validation
              }}
              required
              value={slot}
              disabled={
                (selectedDoctor === "Dr S.M. Holkar" && slot === "morning") ||
                (selectedDoctor === "Dr Sandhya Holkar" && slot === "evening")
              }
            >
              <option value="">Select Slot</option>
              <option value="morning">Morning</option>
              <option value="evening">Evening</option>
            </select>
            <label>Services </label>
            <select
              type="services"
              onChange={(e) => setServices(e.target.value)}
              required
            >
              <option value="">Select Services</option>
              <option value="Diabetes">Diabetes</option>
              <option value="Blood Pressure">Blood Pressure</option>
              <option value="Gynaceology">Gynaceology</option>
              <option value="Routine check ups">Routine check ups</option>
              <option value="Drug De-addiction">Drug De-addiction</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {/* <label>Age</label> */}

          <input
            type="number"
            id="select"
            placeholder="Age"
            onChange={(e) => setAge(e.target.value)}
            required
          />
          {/* <label>Address</label> */}

          <input
            type="address"
            placeholder="Address"
            onChange={(e) => setAddress(e.target.value)}
          />

          <button
            className="button"
            disabled={!isSlotValid || !validateSlot(selectedDoctor, slot)}
          >
            Submit
          </button>
          <p>
            Click here -{" "}
            <Link
              to="/PatientDashboard"
              style={{
                backgroundColor: "#888",
                color: "white",
                padding: "10px 20px",
                borderRadius: "5px",
                textDecoration: "none",
                display: "inline-block",
              }}
            >
              Back to Dashboard
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
}

export default Adminappointment;
