import React from 'react'
import { IconContext } from "react-icons";
import { FiPhoneCall } from 'react-icons/fi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import {AiFillCar,AiOutlineCopyrightCircle} from 'react-icons/ai';
import { Link } from 'react-router-dom'
import "./Fot.css"
export default function Fter() {
  const currentYear = new Date().getFullYear();
   return (
    <>
    <div className="phone">   <IconContext.Provider value={{ color: "white", size: "2rem", }}>
            <li><a href='tel:+919406629851' title='Call Me'> <FiPhoneCall /></a></li>

          </IconContext.Provider></div>
    <footer>
      <div className='footer my-5'>
        <div className="divb">
          <h3> <span><AiFillCar style={{color:"white"}}/></span>   Parking & Attendant</h3>
          <li id="gap">  In house (For 2 wheelers)</li>
          <li> Across road (For 4 wheelers post 7 pm)</li>
          <li>Attendant bed in Private rooms.</li>
          <li>Patient visting hours -  [ 10am  - 12 pm & 7 - 9 pm ]</li>
          <li>To book an appointment or if you have questions   call us at <a href='tel:+919406629851' title='Call Me'>+91-9406629851</a></li>
        </div>
        <div className="divd">
          <h3> <li>< Link to='//www.google.com/maps/place/Abhinav+Nursing+Home/@22.7178654,67.418027,6z/data=!4m9!1m2!2m1!1sabhinav+nursing+home!3m5!1s0x3962fd0a25cea235:0x8d6acd89446a7df!8m2!3d22.7178654!4d75.855527!15sChRhYmhpbmF2IG51cnNpbmcgaG9tZZIBCGhvc3BpdGFs' rel="noreferrer" target="_blank"><FaMapMarkerAlt style={{fontSize:"30px",color:"white", padding:"0px 5px"}} /></ Link></li>  <span id='spanlocation'>Clinical  Hours</span>  </h3>
          <div className="divba">

            <li>Monday              11 am to 1 pm & 8 -10 pm</li>
            <li>Tueday               11 am to 1 pm & 8 -10 pm</li>
            <li>Wednesday        11 am to 1 pm & 8 -10 pm</li>
            <li>Thursday           11 am to 1 pm & 8 -10 pm</li>
            <li>Friday                11 am to 1 pm & 8 -10 pm</li>
            <li>Saturday            11 am to 1 pm & 8 -10 pm</li>
            <li>Sunday             As per prior appointment only</li>
          </div>
        </div>
      </div>
      <div className="i">
        <h6> Copyright <AiOutlineCopyrightCircle/> {currentYear} Abhinav Nursing Home. All rights reserved.</h6>
        <h6>Website created by   <a href="https://viemit.com/" rel="noreferrer" target="_blank">   Viem IT</a> </h6>
      </div>
      </footer>

    </>
  )
}
