
import React from 'react';

import "./hos.css"
import swal from 'sweetalert';
import Ticker from './Ticker';
export default function Services() {

    const displayview = (id) => {

        switch (id) {
            case 1: swal(
              "Diabetes Clinic ",
                ` Diabetes is one of the major cause of deaths caused by Cardiac and Renal disorders.  Incidence of Diabetes is increasing day by day & India has become 2nd largest population suffering from Diabetes across the globe
            Only 13 - 25% of Diabetic patients have adequate control of their sugar level. 
            This is an alarming situation and therefore, Abhinav Nursing Home extends long term economical care to patients suffering from Diabetes under able guidance of Dr S.M. Holkar ( connect a link )_ We provide check up at concessional rates for long term disease.
            `);

                break;
            case 2: swal(
                "Blood pressure clinics",`Blood pressure clinics  - High Blood pressure  (Hypertension) is one of the major cause resulting in cerebral stroke, heart disease & kidney failure.  Many a times, because of absence of symptoms it remains undetected in general population. Even in patients who are under treatment because of lack of symptoms they do not visit their Doctors regularly & suffer from the complications. Abhinav Nursing Home has facility of regular check ups for these patients at affordable rates. We provide check up at concessional rates for long term disease.`);

                break;
            case 3: swal( "Gynaceology clinic",     `Gynacology clinic – Routine gynaecological facility is available and planned major & minor surgical procedures are carried out.`);

                break;
            case 4: swal( "Routine check ups",`Routine check ups – Facilities for all common medical conditions pertaining to infections, Heart,  Liver, Lungs , etc. are available . `);

                break;
            case 5: swal( "Investigations",
             `A – Facility for collection of blood samples for various blood tests is available.  We also provide cheaper package which consist of Thyroid, Liver , Kidney, Lipid & Anaemia profiles.

 B – ECG & Instant blood sugar test is available round the clock. `);

                break;
            case 6: swal(" Drug De-addiction",`
            Drug De-addiction - Prevalence of addiction is increasing in all the major cities, resulting in untimely deaths due to accidents & suicidal tendencies. In the motivated subjects the first part of treatment is detoxification, which is available at Abhinav Nursing Home. Subsequently, the beneficiaries are transferred to Ankur Rehab centre, Dharampuri or to any centre of their choice. `);

                break;
            
            case 9: swal("Affordale Private rooms",`  Well equip operation theatre is available.
            Outside Doctors are allowed to visit & admit their patients.

              Private Rooms - Rs 2000/- ( heater / cooler available on demand )
            Semi Private Rooms - Rs 1000 / -
            General Wards - Rs 500 /-

            Beds in private, semi –private & general wards are available at much cheaper rates. `);

                break;


            default:
                break;
        }

    }



    return (
        <>  
          <div className="space"></div>
            <Ticker/>
            <section className="container ">
                <div className="flex-direction">
                    <div className="card-services mt mtt ">
                        <img src="\assets\images\Diabetes clinic.jpg" alt="heart " />
                        <h5 className="text-center " > Diabetes clinic</h5>
                        <button type="button" className="btn-bg" onClick={() => displayview(1)}>Read-More</button>


                    </div>
                    <div className="card-services mt ">
                        <img src="\assets\images\Blood.jpg" alt="heart " />
                        <h5 className='text-center '>Blood Pressure clinic </h5>
                        <button className="btn-bg" onClick={() => displayview(2)}>Read-More</button>
                    </div>
                    <div className="card-services mt ">
                        <img src="\assets\images\Gynaceology clinic.jpg" alt="heart " />
                        <h5 className='text-center '> Gynaceology clinic</h5>
                        <button className="btn-bg" onClick={() => displayview(3)}>Read-More</button>
                    </div>



                </div>
                {/* second   */}
                <div className="flex-direction">
                    <div className="card-services ">
                        <img src="\assets\images\Routine check ups.jpg" alt="Routine check ups" />
                        <h5 className='text-center '> Routine check ups</h5>
                        <button className="btn-bg" onClick={() => displayview(4)}>Read-More</button>
                    </div>
                    <div className="card-services ">
                        <img src="\assets\images\Investigations2.jpg" alt="  Investigations" />
                        <h5 className='text-center '>  Investigations </h5>
                        <button className="btn-bg" onClick={() => displayview(5)}>Read-More</button>
                    </div>
                    <div className="card-services ">
                        <img src="\assets\images\drugs.jpg" alt="Drug De-addiction " />
                        <h5 className='text-center '>Drug De-addiction </h5>
                        <button className="btn-bg" onClick={() => displayview(6)}>Read-More</button>
                    </div>
                </div>
                {/* Third */}
                <div className="flex-direction">
                 
                    <div className="card-services ">
                        <img src="\assets\images\SemiPriavte Ward.jpg" alt=" Affordale Private rooms" />

                        <h5 className='text-center '> Affordale Private Rooms</h5>
                        <button className="btn-bg" onClick={() => displayview(9)}>Read-More</button>
                    </div>
                </div>
          
            </section>

        </>
    )
}

