import { createContext, useContext, useEffect, useState } from "react";
import {onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import { auth } from "../firebase";
const UserContext = createContext();


export const AuthContextProvider = ({ children }) => {
    const [user ,setUser]=useState()

const signIn=(email,password)=>{
    return signInWithEmailAndPassword(auth,email,password)

}
  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth, (currentUser)=>{
        console.log(user)
        setUser(currentUser)
    });
    return()=>{
        unsubscribe();
    }
  },[])
  return <UserContext.Provider value={{ user,signIn}}> {children}</UserContext.Provider>;
};

export const UserAuth=()=>{
    return useContext(UserContext);
};
