import React from "react";
import "./SpecialServices.css";

const SpecialServices = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button onClick={onClose} className="btn-close">
          {/* Close */}
        </button>
        <h2>Special Services</h2>
        <p>
          <b>Tuesday Special clinic</b>:for High blood pressure and Diabetic patients
          available at discounted rate of Rs.450 every 2ed month. 
          </p>
          <p>   with yearly increment of Rs. 50/-. </p>
          <p> (follow up every alternate Tuesday is mandatory to continue availing
          this benifit).
        </p>
        <br />
        <hr />
        <h2>Additional Services:</h2>
        <ul>
          <li>ECG</li>
          <li>Blood Sugar</li>
          <li>Nebulization</li>
          <li>
            18 Test Package (Liver, Kidney, Serum Iron, Lipid) at concessional
            rates
          </li>
        </ul>
      </div>
    </div>
  );
};

export default SpecialServices;
