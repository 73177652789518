import { useState } from "react";
import "./gallery.css"
export default function Changedata({ imglw1, imglw2, imglw3, imgOT1, imgOT2, imgOT3, imgpw1, imgpw2, imgpw3 }) {

    const [image, setimage] = useState({
        imgUrl: imglw3,
        imgUrlseconded: imgpw1,
        imgUrlthird: imgOT1,
    });

    const changeimage = (newimage, updateimg, modimg) => {
        setimage({
            imgUrl: newimage,
            imgUrlseconded: updateimg,
            imgUrlthird: modimg
        });
    }
    const startup = () => {

        window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    }
    return (
        <>
            <div className="space"></div>
            <div className="container-height">
                <div id="carouselExampleDark" className="carousel carousel-dark slide" data-bs-ride="carousel">
                    <div className="carousel-indicators">
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="1" aria-label="Slide 2"></button>
                        <button type="button" data-bs-target="#carouselExampleDark" data-bs-slide-to="2" aria-label="Slide 3"></button>
                    </div>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-bs-interval="10000">
                            <img src={image.imgUrl} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">

                            </div>
                        </div>
                        <div className="carousel-item" data-bs-interval="2000">
                            <img src={image.imgUrlseconded} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">

                            </div>
                        </div>
                        <div className="carousel-item">
                            <img src={image.imgUrlthird} className="d-block w-100" alt="..." />
                            <div className="carousel-caption d-none d-md-block">

                            </div>
                        </div>

                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            <div className="div-item-component">
                <div className="news-iteam-component color bg-first" onClick={() => startup()} >
                    <button className="btn-design" onClick={() => changeimage(imglw1, imglw2, imglw3)}>Exterior</button>
                </div>
                <div className="award-iteam-component color bg-Seconded" onClick={() => startup()}> <button className="btn-design" onClick={() => changeimage(imgOT1, imgOT2, imgOT3)}>OT</button></div>
                <div className="information-iteam-component color bg-third" onClick={() => startup()} > <button className="btn-design" onClick={() => changeimage(imgpw1, imgpw2, imgpw3)}>Wards</button></div>
            </div>

            <div className="div-item-componented" onClick={() => startup()} >

                <button className="btn-seconsed" onClick={() => changeimage(imglw1, imglw2, imglw1)}>Exterior</button>

                <button className="btn-seconsed" onClick={() => changeimage(imgOT1, imgOT2, imgOT3)}>OT</button>
                <button className="btn-seconsed" onClick={() => changeimage(imgpw1, imgpw2, imgpw3)}>Wards</button>
            </div>



        </>
    )
}
