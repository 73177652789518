import React, { useRef, useEffect } from "react";
import "./Nav.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { FaMapMarkerAlt } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { SiWhatsapp, SiGoogletranslate } from "react-icons/si";
import { IconContext } from "react-icons";
import { NavLink, Link } from "react-router-dom";

export default function Navbar() {
  const googleTranslateElementInit = () => {
    if (!window.google || !window.google.translate) return;
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    if (!document.querySelector("#google-translate-script")) {
      const addScript = document.createElement("script");
      addScript.id = "google-translate-script";
      addScript.setAttribute(
        "src",
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
      );
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    } else {
      googleTranslateElementInit(); // If script is already there, just initialize.
    }
  }, []);

  const hidelogo = useRef(null);
  const style = useRef(null);
  const nav = useRef(null);
  const vcr = useRef(null);

  function responsive() {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    style.current.classList.toggle('vcr');
    nav.current.classList.toggle('nav');
    vcr.current.classList.toggle('vcr');
    hidelogo.current.classList.toggle('hide');
  }

  return (
    <>
      <nav className="navbar" ref={nav}>
        <strong className="spaninlined hide" ref={hidelogo}>
          <Link to="/">Abhinav Nursing Home</Link>
        </strong>
        <div className="burger">
          <IconContext.Provider
            value={{ size: "1.8rem", className: "global-class-name" }}
          >
            <li onClick={responsive}>
              <GiHamburgerMenu />
            </li>
          </IconContext.Provider>
        </div>
        <ul className="ul" ref={style}>
          <li className="logo">
            {" "}
            <Link to="/" onClick={responsive}>
              <img src="\assets\images\hospital-logo.webp" alt="logo" />
            </Link>
          </li>
          <li>
            <NavLink to="/" onClick={responsive}>
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/Doctors" onClick={responsive}>
              Doctors
            </NavLink>
          </li>
          <li>
            <NavLink to="/About" onClick={responsive}>
              About
            </NavLink>
          </li>
          <li>
            <NavLink to="/Services" onClick={responsive}>
              Services
            </NavLink>
          </li>

          <li>
            <NavLink to="/Gallery" onClick={responsive}>
              Gallery
            </NavLink>
          </li>
          {/* <li>
            <NavLink to="/JobSection" onClick={responsive}>
              Jobs
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/Contact" onClick={responsive}>
              Contact us
            </NavLink>
          </li>
          <li>
            <NavLink to="/BioMedicalWaste" onClick={responsive}>
              Bio medical waste
            </NavLink>
          </li>
        </ul>
        <ul className="sm" ref={vcr}>
          <IconContext.Provider
            value={{ size: "2rem", className: "global-class-name" }}
          >
            <li onClick={responsive}>
              <NavLink
                to="//www.google.com/maps/place/Abhinav+Nursing+Home/@22.7178654,67.418027,6z/data=!4m9!1m2!2m1!1sabhinav+nursing+home!3m5!1s0x3962fd0a25cea235:0x8d6acd89446a7df!8m2!3d22.7178654!4d75.855527!15sChRhYmhpbmF2IG51cnNpbmcgaG9tZZIBCGhvc3BpdGFs"
                rel="noreferrer"
                target="_blank"
              >
                <FaMapMarkerAlt />
              </NavLink>
            </li>
          </IconContext.Provider>

          <IconContext.Provider
            value={{ size: "2rem", className: "global-class-name" }}
          >
            <li>
              <NavLink
                to="//wa.me/+919406629851"
                onClick={responsive}
                rel="noreferrer"
                target="_blank"
              >
                <SiWhatsapp />
              </NavLink>
            </li>
          </IconContext.Provider>
          <li id="google_translate_element"></li>
        </ul>
      </nav>

      <div className="phone">
        {" "}
        <IconContext.Provider value={{ color: "white", size: "2.5rem" }}>
          <li>
            <a href="tel:+919406629851" title="Call Me">
              {" "}
              <FiPhoneCall />
            </a>
          </li>
        </IconContext.Provider>
      </div>
    </>
  );
}
