import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../Pages/AuthContext';
function PrivateRoute({children}) {
    const {user} = UserAuth()

    if(!user){
        return <Navigate to='/AdminLogin'/>
    }
  return children
}


export default PrivateRoute;
