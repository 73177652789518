import React from "react";
import "./About.css";
import Ticker from "./Ticker";
import { Link } from "react-router-dom";
export default function About() {
  return (
    <>
      <div className="space"></div>
      <Ticker />
      <div className="aboutA">
        <h3>Abhinav Nursing Home - 20 + years old</h3>
        <p>
          <img src="\assets\images\front.jpg" />
          With more than 25 years of nursing experience, Abhinav Nursing Home
          has successfully provided clinical health care and services in field
          of Diabetes, Drug De-addiction, Blood pressure management, Gynacology
          & General Pratice. Under able guidance of senior doctors-{" "}
          <Link to="/Docter"> Dr S.M. Holkar </Link> and{" "}
          <Link to="/Docter"> Dr Sandhya Holkar </Link> , ANH have successfully
          cured many patients & have performed many general surgeries and
          deliveries. We have also organised routine camps for free health check
          ups and vaccines & have been active member of various established
          bodies for eg - MPNA. Please download our app for booking and to know
          about health camps. Doctor is an academic degree, awarded by a
          university or similar institution.
        </p>
      </div>
      <hr />
      <div className="card-slider-animation">
        <h1 id="testmonial-css">Testimonials</h1>
        <div className="quotes-famous">
          <div className="quotes-famous__track">
            <div className="quotes-famous__quote">
              <div className="quotes-famous__text">
                <p>" Knowledgeable Doctors"</p>
                <h1 className="center">Name - Mr Shinde </h1>
              </div>
            </div>
            <div className="quotes-famous__quote">
              <div className="quotes-famous__text">
                <p>"Good Doctors & cordial staff"</p>
                <h1 className="center">Name - Mr Bhatwadekar </h1>
              </div>
            </div>
            <div className="quotes-famous__quote">
              <div className="quotes-famous__text">
                <p>" Nice services"</p>
                <h1 className="center">Name - Mr Goud </h1>
              </div>
            </div>
            <div className="quotes-famous__quote">
              <div className="quotes-famous__text">
                <p>" Good................."</p>
                <h1 className="center">Name - Apeksha </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
