import React, { useState } from "react";
import { toast } from "react-toastify";
import './JobSection.css';

function JobSection() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [position, setPosition] = useState("");
  const [resume, setResume] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !email || !phone || !position || !resume) {
      toast.error("Please fill all the fields!", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("position", position);
    formData.append("resume", resume);

    try {
      toast.success("Resume uploaded successfully!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (error) {
      toast.error("Failed to upload resume. Please try again.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const handleFileChange = (e) => {
    setResume(e.target.files[0]);
  };

  return (
    <div className="cv-section">
      <h2>Apply for a Job Position</h2>
      <form className="cv-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
        </div>

        <div className="form-group">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>

        <div className="form-group">
          <input
            type="tel"
            id="phone"
            pattern="[0-9]{10}"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Enter your phone number"
            required
          />
        </div>

        <div className="form-group">
          <input
            type="text"
            id="position"
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder="Enter the position you are applying for"
            required
          />
        </div>

        <div className="form-group">
          <input
            type="file"
            id="resume"
            onChange={handleFileChange}
            accept=".pdf, .doc, .docx"
            required
          />
        </div>

        <button type="submit" className="submit-btn">
          Submit
        </button>
      </form>
    </div>
  );
}

export default JobSection;
