import React from "react";
import "./gallery.css";
import Changedata from "./Changedata";

export default function Gallery() {
  return (
    <>
      <Changedata
        imglw1="\assets\images\front.jpg"
        imglw2="\assets\images\Side.png"
        imglw3="\assets\images\LW_1.jpg"
        imgOT1="\assets\images\OT_1.jpg"
        imgOT2="\assets\images\OT_2.jpg"
        imgOT3="\assets\images\OT_3.jpg"
        imgpw1="\assets\images\Private Ward_2.jpg"
        imgpw2="\assets\images\Private Ward.jpg"
        imgpw3="\assets\images\SemiPriavte Ward.jpg"
      />
    </>
  );
}
