import React from 'react'
import "./Contact.css"
import { FaWhatsapp } from 'react-icons/fa';
import {BsLinkedin } from 'react-icons/bs';
import { SiGmail } from 'react-icons/si';
import { Link, NavLink} from "react-router-dom";

export default function Contact() {
  return (
    <> 
    <div className="space"></div>
<div className="main-container-contact">
<div className="address-container"> 

<div className="address-information">
<strong> Address</strong>
<p>Abhinav Nursing Home

17/1 Rajwada Chowk,
Indore – 452001, M.P.</p>


<strong> Call Us:</strong>
<p><span> Whatsapp</span> <span> Landline</span></p>
            <p><span><a href='tel:+919406629851' title='Call Me'>+919406629851</a></span> <span><a href='tel:+91 0731-2536940' title='Call Me'>0731-2536940</a></span></p>
            <strong> Review us:</strong>
<Link className='plink' to='https://www.google.com/search?q=abhinav+nursing+home+indore&sca_
esv=559635945&ei=8e7mZMbjHuCTseMPubmYyAM&gs_ssp=eJzj4tVP1zc0TDJPS8vLSk4zYLRSMagwt
jQzSksxSDQyTU5NNDI2tTKosEgxS0xOsbA0MTFLNE9J85JOTMrIzEssU8grLSrOzEtXyMjPTVXIz
EvJL0oFAOuIGfA&oq=abhinav+nurshing+home&gs_lp=Egxnd3Mtd2l6LXNlcnAiFWFiaGluYXYgbnVyc2hpbmcgaG9tZSoCCAkyChAAGA0YgAQYsAMy
ChAAGA0YgAQYsAMyChAAGA0YgAQYsAMyEBAuGA0YgAQYxwEYrwEYsAMyCRAAGB4YDRiwAzILEAAYCBgeGA0YsAMyCxAAGIoFGIYDGLADMgsQABiKBRiGAxiwAzILEAAYigUYhgMYsAMy
FhAuGA0YgAQYxwEYrwEYyAMYsAPYAQEyFhAuGA0YgAQYxwEYrwEYyAMYsAPYAQFI_zRQAFgAcAF4AJABAJgBAKABAKoBALgBAcgBAOIDBBgBIEGIBgGQBgu6BgQIARgI&sclient=gws-
wiz-serp#lrd=0x3962fd0a25cea235:0x8d6acd89446a7df,3,,,,'><p>Click here for Review</p></Link>
</div>
<div className="map-address"><div className="map-main-google-map">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1884302.8988033424!2d73.746152!3d22.717865399999994!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd0a25cea235%3A0x8d6acd89446a7df!2sAbhinav%20Nursing%20Home!5e0!3m2!1sen!2sin!4v1659681571033!5m2!1sen!2sin"  title="myFrame" width="350" height="220" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
  <div className="follow-contact">
          
<strong> Follow us</strong>
              <p> <span><NavLink to='//wa.me/+919406629851'  rel="noreferrer" target="_blank">< FaWhatsapp /></NavLink></span>
                <span><a href="mailto: nursinghomeabhinav@gmail.com"><SiGmail/></a></span>
                {/* <span><BsFacebook/></span> */}
                <span> <NavLink to='//www.linkedin.com'  rel="noreferrer" target="_blank"><BsLinkedin/></NavLink></span>
                
</p>
</div>
</div>
</div>
<div className="enquiry-container"> 
<strong>Make an enquiry</strong>
          <form action="https://formspree.io/f/xzbqdvwy" method='post'>
 <div className="name"> <label htmlFor="customar-name"> Name:</label>
  <input type="text" id='customar-name' name='Name' placeholder='Enter your name'/></div>

  <div className="email">
  <label htmlFor="customar-email"> Email:</label>
  <input  type="email" id='customar-email' name='Email' placeholder='Enter your email'/>
  </div>
  <div className="phone-number">
  <label htmlFor="phone"> Phone:</label>
<input type="tel" id="phone" name="phone" pattern="[0-9]{10}" placeholder='Enter your phone Number:'/>
</div>
<div className="feedback">
  <label htmlFor="user-feedback">Message:</label>
 <textarea id="user-feedback" name="Message" rows="2" cols="25" placeholder='Please fill the Message form:'/>
</div>
<input type="submit" value="Submit-Now" />
</form>
</div>

</div>


   
    </>
  )
}
