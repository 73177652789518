import React from 'react';
import './BioMedical.css';

function BioMedicalWaste() {
  // const handleDownload = () => {
  //   const pdfUrl = 'https://firebasestorage.googleapis.com/v0/b/auth-admin-9f547.appspot.com/o/files%2FRep_BMW_FormII.pdf?alt=media&token=f30dfded-1fbd-425e-905a-25d694433228';
  //   const urlObject = new URL(pdfUrl);
  //   window.open(urlObject.href, '_blank');
  // };

  return (
    <div className='bio'>
      <div className='image-container'>
        <img
          src='/assets/images/Rep_BMW_FormII_page-0001.jpg'
          // src ='https://firebasestorage.googleapis.com/v0/b/auth-admin-9f547.appspot.com/o/files%2Fimage%20(1).png?alt=media&token=46caed27-3624-4544-8d61-46de87349e69&_gl=1*1kitby0*_ga*MTgxNDkyMjU4OS4xNjgxMTk5NzI1*_ga_CW55HF8NVT*MTY5NTg4MDk5OS4xMzAuMS4xNjk1ODgxMDQ5LjEwLjAuMA..'
          alt=''
          className='responsive-image'
        />
        {/* <button className='download-button' onClick={handleDownload}>
          Download PDF
        </button> */}
      </div>
    </div>
  );
}

export default BioMedicalWaste;
